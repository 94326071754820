import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch CSR Template
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchCsrTemplate = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr/external_investments/template`))
}

/**
 * Import external investments
 * @param {String} corporationId
 * @param {Object} formData
 * @return {Promise}
 */
export const importExternalInvestments = (corporationId, formData) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr/external_investments/import`), formData)
}

/**
 * Update external investment
 * @param {String} corporationId
 * @param {String} externalInvestmentId
 * @param {Object} formData
 * @return {Promise}
 */
export const updateExternalInvestment = (corporationId, externalInvestmentId, formData) => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/corporations/${corporationId}/csr/external_investments/${externalInvestmentId}`), formData)
}
