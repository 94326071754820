<template>
  <div class="csr-upload-wrapper">
    <div>
      <p class="is-size-4">1. Download our Template to your computer</p>
      <a :href="templateUrl" class="button ml-4 my-5">Download Template</a>
      <p class="is-size-4">2. Fill in the Template</p>
      <p class="is-size-6 ml-4 my-5">Open the Template in your favourite spreadsheet software and fill in the mandatory fields for each security holder transaction and save as a .csv file.</p>
      <p class="is-size-4">3. Upload completed file (.csv file)</p>
      <b-field class="file ml-4 my-5" :class="{'has-name': !!file}">
        <b-upload v-model="selectedFile" @input="selectFile" :disabled="isImporting" class="file-label" accept=".csv" required validationMessage="Please select a file">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Click to upload (Only .csv)</span>
          </span>
          <span class="file-name" v-if="file">{{ file.name }}</span>
        </b-upload>
      </b-field>
      <b-button @click="uploadFile" :disabled="!file || isImporting" :class="`ml-4 is-blue ${isImporting ? 'is-loading' : ''}`">Submit</b-button>
    </div>
    <Flash
      title="Notice!"
      :content="flashContent || ''"
      :show="!!flashContent"
      :hideFlash="hideFlash"
    ></Flash>
  </div>
</template>
<script>
import Flash from '@components/modal/slide-down-flash.vue'

export default {
  props: {
    file: {
      type: File,
    },
    isImporting: {
      type: Boolean,
      default: false,
    },
    templateUrl: {
      type: String,
    },
    flashContent: {
      type: String,
    },
  },
  components: {
    Flash,
  },
  watch: {
    flashContent(value) {
      if (!value) return
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 5000)
    },
    file(value) {
      this.selectedFile = value
    },
  },
  data() {
    return {
      selectedFile: null,
      timer: null,
    }
  },
  methods: {
    hideFlash() {
      this.$emit('onHideFlash')
    },
    selectFile(file) {
      this.$emit('onSelectFile', file)
    },
    uploadFile() {
      this.$emit('onUploadFile')
    },
  },
}
</script>
