<template>
  <div class="csr-external-investments-upload-wrapper">
    <nav class="breadcrumb is-medium mb-6" aria-label="breadcrumbs">
      <ul>
        <li><a @click="toLedgerPage" class="previous">Transaction Ledger</a></li>
        <li class="is-active"><a href="#" aria-current="page">Upload Multiple Transactions</a></li>
      </ul>
    </nav>
    <CsrUpload
      :file="file"
      :is-importing="isImporting"
      :template-url="templateUrl"
      :flash-content="flashContent"
      @onHideFlash="hideFlash"
      @onSelectFile="selectFile"
      @onUploadFile="uploadFile" />
  </div>
</template>
<script>
import { fetchCsrTemplate, importExternalInvestments } from '@api/addy-plus/external-investments'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import CsrUpload from '@components/upload/csr-upload.vue'

export default {
  title: 'Corporation CSR',
  mixins: [pageTitleMixin],
  components: {
    CsrUpload,
  },
  data() {
    return {
      file: null,
      templateUrl: null,
      isImporting: false,
      flashContent: null,
    }
  },
  created() {
    this.init()
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  methods: {
    init() {
      fetchCsrTemplate(this.corporationId).then((res) => {
        if (!res.success || !res.data.templateUrl) return
        this.templateUrl = res.data.templateUrl
      })
    },
    hideFlash() {
      this.flashContent = null
    },
    selectFile(file) {
      this.file = file
    },
    uploadFile() {
      if (!this.file) return
      this.isImporting = true
      const formData = new FormData()
      formData.append('file', this.file)
      importExternalInvestments(this.corporationId, formData).then((res) => {
        this.isImporting = false
        if (!res.success) return
        this.file = null
        this.flashContent = res.data.message
      })
    },
    toLedgerPage() {
      this.$router.replace(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/transactionLedger`)
    },
  },
}
</script>
