/**
 * Get Page Title
 * @param {Object} vm vue instance
 * @return {String}
 */
function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function' ?
      title.call(vm) :
      title
  }
}

export default {
  created() {
    const title = getTitle(this)
    if (title) {
      document.title = title + ' - addy Business'
    }
  }
}
